import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWallets } from "../../redux/features/walletSlice";

import { WalletItem } from "./WalletItem/WalletItem";
import { WithdrawItem } from "./WithdrawItem/WithdrawItem";

import styles from "./WalletPage.module.scss";

export const WalletPage = (props) => {
  const dispatch = useDispatch();
  const { wallets, tokens } = useSelector((state) => state.wallet);
  const { userId } = useSelector((state) => state.user);
  const [activeView, setActiveView] = useState("myCat");

  useEffect(() => {
    dispatch(fetchWallets(userId));
  }, []);

  return (
    <div className={styles.walletContainer}>
      <div className={styles.walletBalanceContainer}>
        {tokens.map((token) => {
          const wallet = wallets.find((w) => w.token_id === token.id) || {
            amount: 0,
          };
          return (
            <div key={token.id} className={styles.walletItem}>
              <img
                src={require(`../../assets/wallet/${token.name}.png`)}
                alt={token.name}
              />
              <span>{wallet.amount}</span>
            </div>
          );
        })}
      </div>
      <div className={styles.walletContent}>
        <div className={styles.topButtons}>
          <button onClick={() => setActiveView("withdraw")}>
            {props.lang.withdraw()}
          </button>
          <button onClick={() => setActiveView("exchange")}>
            {props.lang.exchange()}
          </button>
        </div>

        {activeView === "withdraw" ? (
          <>
            <WithdrawItem
              token="USDT"
              openModal={props.openModal}
              setCurrentToken={props.setCurrentToken}
            />
            <WithdrawItem
              token="PEPE"
              openModal={props.openModal}
              setCurrentToken={props.setCurrentToken}
            />
            <WithdrawItem
              token="SHIB"
              openModal={props.openModal}
              setCurrentToken={props.setCurrentToken}
            />
          </>
        ) : (
          <>
            {[...tokens].reverse().map((token) => {
              const wallet = wallets.find((w) => w.token_id === token.id) || {
                amount: 0,
              };
              return (
                <WalletItem
                  key={wallet.token_id}
                  tokenName={token.name}
                  amount={wallet.amount}
                  swapRole={token.name === "Liquid"}
                  userId={userId}
                  tokenId={wallet.token_id}
                  openModal={props.openModal}
                />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};
